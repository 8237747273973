export default {
    title: 'ਲਾਇਫ',
    description: 'ਲੋਕਾਂ ਤੋਂ ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਐਮਰਜੈਂਸੀ ਸੇਵਾਵਾਂ ਦੀ ਡਾਇਰੈਕਟਰੀ',
    oxygen: 'ਆਕਸੀਜਨ',
    medicine: 'ਦਵਾਈਆਂ',
    hospital: 'ਹਸਪਤਾਲ',
    ambulance: 'ਐਂਬੂਲੈਂਸ',
    helpline: 'ਹੈਲਪਲਾਈਨ',
    plasma: 'ਪਲਾਜ਼ਮਾ',
    vaccine: 'ਟੀਕਾ',
    searchPlaceholder: 'ਰਾਜ ਜਾਂ ਜ਼ਿਲ੍ਹੇ ਵਿੱਚ ਉਪਲਬਧਤਾ ਦੀ ਜਾਂਚ ਕਰੋ',
    state: 'ਰਾਜ ',
    district: 'ਜ਼ਿਲ੍ਹਾ',
    covid19Stats: 'ਕੋਵਿਡ 19 ਅੰਕੜੇ',
    totalHospitals: 'ਕੁੱਲ ਹਸਪਤਾਲ',
    verified: 'Verified',
    totalAmbulace: 'ਕੁੱਲ ਐਂਬੂਲੈਂਸਾਂ',
    oxygenRequirements: 'ਆਕਸੀਜਨ ਦੀ ਜ਼ਰੂਰਤ',
    contributeData: 'ਪ੍ਰਮਾਣਿਤ ਡੇਟਾ ਪ੍ਰਦਾਨ ਕਰੋ',
    campaigns: 'ਹੋਰ ਮੁਹਿੰਮਾਂ',
    learn: 'ਹੌਰ ਜਾਨੋ',
    partnerWithUs: 'ਸਾਡੇ ਨਾਲ ਭਾਈਵਾਲ ਕਰੋ',
    curatedBy: 'ਦੁਆਰਾ ਤਿਆਰ ਕੀਤਾ',
    supportedBy: 'ਸਹਿਯੋਗੀ',
    poweredBy: 'ਦੁਆਰਾ ਸਹਿਯੋਗੀ',
    github: 'ਗਿਠਬ',
    database: 'ਡਾਟਾਬੇਸ',
    about: 'ਸਾਡੇ ਬਾਰੇ ਜਾਣੋ',
    infoOnCovid: `COVID-19 'ਤੇ ਜਾਣਕਾਰੀ`
};
