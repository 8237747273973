export default {
    title: 'লাইফ',
    description: 'সপ্তাপিত ক্রাউড সোর্সড এমারজেন্সি সেবা নির্দেশিকা',
    oxygen: 'অক্সিজেন',
    medicine: 'দাবাইয়া',
    hospital: 'হসপিটাল',
    ambulance: 'এমবুলেন্স',
    helpline: 'হেল্পলাইন',
    plasma: 'প্লাজমা',
    vaccine: 'ভ্যাকসিন',
    searchPlaceholder: 'রাজ্য বা জেলায় উপলভ্যতার জন্য অনুসন্ধান করুন',
    state: 'রাজ্য',
    district: 'জেলা',
    covid19Stats: 'কোভিড১৯ পরিসংখ্যান',
    totalHospitals: ' মোট হাসপাতালের সংখ্যা ',
    verified: 'যাচাই করা হয়েছে',
    totalAmbulace: 'মোট অ্যাম্বুলেন্স',
    oxygenRequirements: 'অক্সিজেন প্রয়োজনীয়তা',
    contributeData: 'যাচাই করা ডেটা অবদান করুন',
    campaigns: 'আমাদের প্রচার',
    learn: 'শিখুন',
    partnerWithUs: 'আমাদের সাথে যোগ দিন',
    curatedBy: 'তথ্য সংগ্রহ করেছে',
    supportedBy: 'সমর্থিত',
    poweredBy: 'দ্বারা সমর্পিত',
    github: 'গীথব',
    database: 'ডেটাবেস',
    about: 'আমাদের সম্পর্কে জানুন'
};
