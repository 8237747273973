export default {
    title: 'لائف',
    description: 'تصدیق شدہ کراؤڈ سورسڈ ایمرجنسی سروسز ڈائریکٹری',
    all: 'تمام',
    oxygen: 'آکسیجن',
    medicine: 'دوائی',
    hospital: 'ہسپتال',
    ambulance: 'ایمبولینس',
    helpline: 'ہیلپ لائن',
    plasma: 'پلازما',
    vaccine: 'ویکسین',
    searchPlaceholder: 'ریاست یا ضلع میں دستیابی کی تلاش کریں',
    state: 'ریاست',
    district: 'ضلع',
    covid19Stats: 'کوویڈ 19 شماریات',
    totalHospitals: 'کل اسپتال',
    verified: 'تصدیق شدہ',
    totalAmbulace: 'کل ایمبولینس',
    oxygenRequirements: 'آکسیجن ارتکاز کی ضروریات',
    contributeData: 'تصدیق شدہ کوائف میں حصہ ڈالیں',
    campaigns: 'مہمات',
    learn: 'جانیں',
    partnerWithUs: 'ہمارے ساتھ شراکت دار',
    curatedBy: 'کیوریٹڈ از',
    supportedBy: 'کی طرف سے حمایت کی',
    poweredBy: 'چلتا ہے',
    github: 'گیتھب',
    database: 'ڈیٹا بیس',
    about: 'کس کے بارے میں',
    infoOnCovid: `COVID-19 پر معلومات`
};
