export default {
    title: 'లైఫ్',
    description: 'ఉమ్మడిగా ప్రజాలచే సేఖరించి ఆమోదింపబడ్డ అత్యవసర సేవా డైరెక్టరీ',
    oxygen: 'ఆక్సిజన్',
    medicine: 'మందులు',
    hospital: 'ఆసుపత్రి',
    ambulance: 'అంబులేంసు',
    helpline: 'హెల్ప్లైన్',
    plasma: 'ప్లాస్మా',
    vaccine: 'టీకా మందు',
    searchPlaceholder: 'రాస్ట్రం లేదా జిల్లా లో లభ్యత కొరకు శోధించుము',
    state: 'రాస్ట్రం ',
    district: 'జిల్లా',
    covid19Stats: 'కోవిడ్ 19 అంకెలు',
    totalHospitals: 'మొత్తం హాస్పిటల్ల సంఖ్య',
    verified: 'ధృవీకరించబడిన',
    totalAmbulace: 'అన్ని అంబులెన్సుల సంఖ్య',
    oxygenRequirements: 'ఆక్సిజన్ ఆవశ్యకత',
    contributeData: 'సమాచారాన్ని మాతో పంచుకోండి',
    campaigns: 'క్యాంపైన్',
    learn: 'నేర్చుకోనుము',
    partnerWithUs: 'మాతో కలిసి పనిచేయండి',
    curatedBy: 'శేఖరణ',
    supportedBy: 'అదనపు మాదత్తు',
    poweredBy: 'తయారీ',
    github: 'గిట్ హబ్',
    database: 'డాటాబేస్',
    about: 'మా గురించి',
    infoOnCovid: `COVID-19 పై సమాచారం`
};
