export default {
    head: 'અમારા વિશે',
    q1: 'શું તમે અધિકારી કર્મચારી છો?',
    a1: 'ના',
    q2: 'તમારા સ્રોત(sources) શું છે? આ પ્રોજેક્ટ માટે ડેટા કેવી રીતે એકત્રિત કરવામાં આવે છે?',
    a21: 'અમે ડેટા મેળવવા માટે ઘણા સ્રોતોનો ઉપયોગ કરી રહ્યા છીએ. અમારી સ્વયંસેવકો ની ટીમ પરિપત્રો, સૂચનાઓ, ટ્વીટ્સ, અન્ય ડેટાબેસેસ, વેબસાઇટ્સના ડેટા ને સતત શોધે છે. અમે સામાન્ય લોકો દ્વારા સબમિટ કરેલ નોંધપાત્ર ડેટા પણ મેળવીએ છી(crowd source channels  ). સમાન નિર્માણમાં રોકાયેલા ભાગીદારોએ પહેલેથી ચકાસણી કરેલી માહિતીને એકરૂપ પણ કરવામાં આવે છે.',
    a22: 'ડેટા સ્વયંસેવકોના જૂથ દ્વારા માન્ય કરવામાં આવે છે અને એરટેબલ શીટ અને એક API માં પ્રકાશિત થાય છે. API બધા માટે ઉપલબ્ધ છે.',
    a23: 'જો તમે આ વાયરસ સામેની લડતમાં આ ડેટાનો ઉપયોગ કરી શકો તો અમને ખુબ પસંદ આવશે.',
    q3: 'હું આ માટે ડેટા ક્યાંથી શોધી શકું?',
    a31: 'બધા ડેટા એક દ્વારા અહીં ઉપલબ્ધ છે. :',
    a32: 'ભૂલ અહીંયા દાખલ કરો',
    a33: 'ફીડબેક્સ / બગ ફાઇલ અહીં કરવું',
    q4: 'તમે કોણ છો?',
    a4: 'અમે સમર્પિત સ્વયંસેવકોનું એક જૂથ છીએ જે આવતા ડેટાને ક્યુરેટ કરે છે અને તેની ચકાસણી કરે છે કેટલાક સ્રોતોમાંથી. CovidFYI - ડિરેક્ટરી રીપોઝીટરી સ્વરૂપમાં કોવિડ સંસાધનોને એકઠા કરે છે માહિતીની શોધમાં રહેલા લોકોને મદદ કરવા માટેથી  . દર્દીઓ સંબંધિત કોઈપણ વ્યક્તિગત રૂપે ઓળખી શકાય તેવા ડેટાને અમે ક્યારેય એકત્રિત કરતા નથી અથવા બહાર કાઢતા નથી. અમે 1-ઓન -1 કેસ નથી સંભાળતા. CovidFYI એક સહયોગનું ઉત્પાદન છે - , એમાં કોરોનાસાફે સમર્થિત સ્વેસ્ટ પહેલ, સ્ટેપન સ્વયંસેવકો, પીઆઈ ઇન્ડિયા, તબીબી સહાય જૂથ, ભારતીય હેલ્પલાઇન, આઇઆઇએમ-કે સમુદાય સપોર્ટ (ઘણા વધુ) જોડાયેલા છે.',
    q5: 'તમે તમારી માહિતીને કેટલી વખતોવખત ચકાસો છો?',
    a5: 'અમે માહિતીની સમયાંતરે ચકાસણી કરીએ છીએ પરંતુ માહિતી ની વિશાળતા ને લીધે તમે જે શોધી રહ્યા હતા તે જો તમને ન મળે તો અમારી ક્ષમા સ્વીકારો. અમે માહિતી ઉમેરવા, ચકાસવા, સાફ કરવાનું ચાલુ રાખિશુ. અમારી કોશિશ સારી માહિતી આપવાની છે. અમે તમારી મદદ ની અપેક્ષા રાખીએ છીએ . આ માટે દરેકનો આભાર, તમારા બધા વિના આ શક્ય ન હોત.',
    q6: 'તમે આ કરવા માટે સમય અને સંસાધનો શા માટે મૂકી રહ્યાં છો જ્યારે તમે તેમાંથી એક પૈસો પણ નથી મેળવવાના?',
    a6: 'અમે સહયોગની સાચી ભાવના, crowdsourcing, માહિતીનું લોકશાહીકરણ અને સ્વયંસેવા માં વિશ્વાસ કરીએ છીએ. જીવન બચાવવા માટે અમને સપોર્ટ કરો! અમને જોડાઓ.',
    q7: 'તમે અમારી સાથે સ્વયંસેવક કેવી રીતે બની શકો?',
    a7: ` ચકાસેલી માહિતી ઉમેરો (જે પહેલાથી અસ્તિત્વમાં નથી), અસ્તિત્વમાં છે તે ડેટાની ચકાસણી કરો અને
  અપડેટ વેરિફિકેશન સ્ટેટસ શેર કરીને બીજા પ્લેટફોર્મ પર લોકોને 1-1 ની મદદ કરો
  CovidFYI ના સંપર્ક / લિંક્સ, ઉત્પાદન સંબંધિત વિચારો પર નવીનતા લાવો, હેન્ડલ સામાજિક
  મીડિયા & amp; માર્કેટિંગ ઓપરેશન, સ્વયંસેવકો, ઓનબોર્ડિંગ, તમે જે ટીમમાં જોડાવા માંગો છો તે નક્કી કરોઅથવા જ્યારે પણ મદદનો વારો આવે ત્યાં પીચ પર વળગી રહેવું!.`,
    q8: `જો તમારી પાસે ચકાસેલ માહિતી હોય અને કોવિડ્ફાઇ ડેટાબેસને ઉમેરવા માંગતા હોય તો શું કરવું?`,
    a8: `COVID સંસાધનો કેવી રીતે શેર કરવું તે અંગે ડેટા ટિપ્સ ઉમેરતા પહેલા આ વાંચવાની ભલામણ કરીશું `,
    q9: `શું તમારી પાસે જથ્થા માં ચકાસેલી માહિતી છે?`,
    a9: `જો તમે માહિતીને જથ્થામાં, એક્સેલ ફોર્મેટમાં ઉમેરવા માંગો છો, અને તમે એરટેબલથી આરામદાયક નથી, તો અમને Google શીટ અથવા એક્સેલ ફોર્મેટમાં મોકલો`,
    a91: `અથવા અમને ગમે ત્યાં પહોંચો. માહિતી ઉમેરવા માટે અમારી ટીમમાં જોડાઓ.`,
    q10: `શું તમે પણ અમારા જેવી જ પહેલ કરો છો? અથવા કોઈને જાણો છો?`,
    a10: `સમાન ડેટા ધરાવતા સોશિયલ મીડિયા પર કે તમારી કંપની, મિત્રો, કે શિક્ષણ સંસ્થા દ્વારા ચલાવતા અભિયાન સુધી પહોંચો અને એમને અમારા વિશે માહિતી આપો. આ માટે અન્યને સહયોગની પહેલ કરવા વિનંતી કરીએ છીએ કારણ કે આપણે જે સમસ્યા હલ કરી રહ્યા છીએ તેમાં એકીકરણ મહત્વપૂર્ણ છે. અમે
  શ્રેય લેવા નહીં પરંતુ ફક્ત આ માહિતી જરૂરિયાતો સુધી પહોંચવા માંગીએ છીએ. અમારું લક્ષ્ય યોગ્ય લોકોને યોગ્ય માહિતિ યોગ્ય સમયે સરળતાથી પૂરી પાડવાનું છે.`,
    q11: 'ડિસ્ક્લેમર',
    a112: `COVID માહિતી પ્લેટફોર્મ`,
    a113: ` એક સ્વાસ્થ ડિજિટલ હેલ્થ ફાઉન્ડેશન ("સ્વાસ્થ"), CovidFYI, Coronasafe નેટવર્ક દ્વારા બનાવેલું પ્લેટફોર્મ છે જે ભારતભરમાં COVID-19 કટોકટી માટે ચકાસાયેલ crowdsourced કટોકટી સેવાઓ પ્રદાન કરે છે`,
    a114: `COVID માહિતી પ્લેટફોર્મમાં પૂરા પાડવામાં આવેલ તમામ માહિતીની સ્વયંસેવકોના જૂથ દ્વારા તેમની શ્રેષ્ઠ ક્ષમતાઓથી ચકાસણી કરવામાં આવી છે
   અને સંસ્થાઓ દ્વારા COVID પર પૂરા પાડવામાં આવેલ કોઈપણ ડેટા / માહિતી માટેની તમામ જવાબદારીનો અસ્વીકાર કરીએ છીએ. COVID માહિતીના ઉપયોગથી ઉદ્ભવતા કોઈપણ સીધા, પરોક્ષ, આકસ્મિક, પરિણામલક્ષી, વિશેષ, શિક્ષાત્મક, અનુકરણીય, અથવા કોઈ પણ અન્ય નુકસાનની 
  પ્લેટફોર્મ સંસ્થાઓ કોઈ પણ સંજોગોમાં જવાબદાર રહેશે નહીં. COVID માહિતી પ્લેટફોર્મના ઉપયોગથી કોઈપણ નિર્ધારિત પરિણામોની સંસ્થાઓ દ્વારા કોઈ ખાતરી આપવામાં આવતી નથી. COVID માહિતી પ્લેટફોર્મ પર પૂરા પાડવામાં આવેલ કોઈપણ માહિતી વપરાશકર્તાનું વ્યક્તિગત જોખમ પર કરે અને તે માટે સંસ્થાઓ જવાબદાર નથી. આવા ડેટા / માહિતી પ્રદાન કરવા માટેના કોઈપણ પરિણામો વપરાશકર્તાઓ ઉપયોગ કરીને તે સમજે છે. કોવિડ માહિતીપ્રદ
  પ્લેટફોર્મ માહિતીના હેતુ માટે, અન્ય સંસ્થાઓ અને વ્યક્તિઓ દ્વારા સંચાલિત વેબસાઇટ્સની લિંક્સ પ્રદાન કરી શકે છે, પરંતુ આવી સામગ્રી માટે અથવા આવી વેબસાઇટ્સના ઉપયોગ માટે કોઈપણ જવાબદારીનું સમર્થન કરતી અથવા સ્વીકારતી નથી.No warranty of any kind including
  but not limited to the warranties of non-infringement of third party rights,
  title, merchantability, fitness for a particular purpose is given with respect
  to the contents of this website or its hyperlinks to other internet resources.`
};
