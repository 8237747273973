export default {
    head: 'ಬಗ್ಗೆ',
    q1: 'ನೀವು ಅಧಿಕೃತವಾಗಿದ್ದೀರಾ?',
    a1: 'ಇಲ್ಲ.',
    q2: 'ನಿಮ್ಮ ಮೂಲಗಳು ಯಾವುವು? ಈ ಯೋಜನೆಗಾಗಿ ಡೇಟಾವನ್ನು ಹೇಗೆ ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ?',
    a21: ` ಡೇಟಾವನ್ನು ಪಡೆಯಲು ನಾವು ಅನೇಕ ಮೂಲಗಳನ್ನು ಬಳಸುತ್ತಿದ್ದೇವೆ. ನಮ್ಮ ಆಂತರಿಕ ಸ್ವಯಂಸೇವಕ ತಂಡ ಸ್ಕೌಟ್ಸ್
    ಸುತ್ತೋಲೆಗಳು, ಸೂಚನೆಗಳು, ಟ್ವೀಟ್‌ಗಳು, ಇತರ ಡೇಟಾಬೇಸ್‌ಗಳು, ವೆಬ್‌ಸೈಟ್‌ಗಳಿಂದ ಡೇಟಾಕ್ಕಾಗಿ. ನಾವು ಕೂಡ
    ಕ್ರೌಡ್‌ಸೋರ್ಸ್ಡ್ ಚಾನಲ್‌ಗಳಿಂದ ಗಮನಾರ್ಹವಾದ ಡೇಟಾವನ್ನು ಪಡೆದುಕೊಳ್ಳಿ - ಸಲ್ಲಿಸಿದ ಫಾರ್ಮ್‌ಗಳು
    ಸಾಮಾನ್ಯ ಜನ. ಇದೇ ರೀತಿಯ ಪಟ್ಟಿ ರಚನೆಯಲ್ಲಿ ತೊಡಗಿರುವ ಪಾಲುದಾರ ಉಪಕ್ರಮಗಳು ವಿಲೀನಗೊಂಡಿವೆ
    ಅವರ ಪರಿಶೀಲಿಸಿದ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ನಮ್ಮೊಂದಿಗೆ ಪ್ರಯತ್ನಗಳು.`,
    a22: `ಡೇಟಾವನ್ನು ಸ್ವಯಂಸೇವಕರ ಗುಂಪಿನಿಂದ ಮೌಲ್ಯೀಕರಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಏರ್‌ಟೇಬಲ್‌ನಲ್ಲಿ ಪ್ರಕಟಿಸಲಾಗುತ್ತದೆ
    ಶೀಟ್ ಮತ್ತು API. API ಎಲ್ಲರಿಗೂ ಲಭ್ಯವಿದೆ`,
    a23: `. ಈ ವೈರಸ್ ವಿರುದ್ಧದ ಹೋರಾಟದಲ್ಲಿ ನೀವು ಈ ಡೇಟಾವನ್ನು ಬಳಸಬಹುದಾದರೆ ನಾವು ಅದನ್ನು ಪ್ರೀತಿಸುತ್ತೇವೆ.`,
    q3: `ಇದಕ್ಕಾಗಿ ಡೇಟಾವನ್ನು ನಾನು ಎಲ್ಲಿ ಕಂಡುಹಿಡಿಯಬಹುದು?`,
    a31: `ಎಲ್ಲಾ ಡೇಟಾವು ಒಂದು ಮೂಲಕ ಲಭ್ಯವಿದೆ`,
    a32: `ನಲ್ಲಿ ಸಮಸ್ಯೆಯನ್ನು ಫೈಲ್ ಮಾಡಿ `,
    a33: `ನೀವು ಪ್ರತಿಕ್ರಿಯೆಗಳನ್ನು ಹೊಂದಿದ್ದರೆ / ದೋಷವನ್ನು ವರದಿ ಮಾಡಲು ಬಯಸಿದರೆ.`,
    q4: `ನೀವು ಯಾರು?`,
    a4: `ನಾವು ಸಮರ್ಪಿತ ಸ್ವಯಂಸೇವಕರ ಗುಂಪಾಗಿದ್ದು, ಅವರು ಬರುವ ಡೇಟಾವನ್ನು ಪರಿಶೀಲಿಸುತ್ತಾರೆ ಮತ್ತು ಪರಿಶೀಲಿಸುತ್ತಾರೆ
    ಹಲವಾರು ಮೂಲಗಳಿಂದ. ಕೋವಿಡ್‌ಎಫ್‌ವೈಐ - ಕೋವಿಡ್ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಎ ರೂಪದಲ್ಲಿ ಒಟ್ಟುಗೂಡಿಸುತ್ತದೆ
    ಮಾಹಿತಿಯನ್ನು ಹುಡುಕುವ ಜನರಿಗೆ ಸಹಾಯ ಮಾಡಲು ಡೈರೆಕ್ಟರಿ ಭಂಡಾರ. ನಾವು ಎಂದಿಗೂ ಸಂಗ್ರಹಿಸುವುದಿಲ್ಲ ಅಥವಾ
    ರೋಗಿಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ವೈಯಕ್ತಿಕವಾಗಿ ಗುರುತಿಸಬಹುದಾದ ಯಾವುದೇ ಡೇಟಾವನ್ನು ಬಹಿರಂಗಪಡಿಸಿ. ನಾವು ಮಾಡುವುದಿಲ್ಲ
    1-ಆನ್ -1 ಪ್ರಕರಣಗಳ ನಿರ್ವಹಣೆ. ಇದು ಸಹಯೋಗದ ಉತ್ಪನ್ನವಾಗಿದೆ - ಕೋವಿಡ್ಫಿ, ಎ
    ಸ್ಟೊಪೋನ್ ಸ್ವಯಂಸೇವಕರಾದ ಪಿಐಇಂಡಿಯಾ, ಕೊರೋನಾಸಾಫ್ ಬೆಂಬಲದೊಂದಿಗೆ ಸ್ವಾಸ್ತ್ ಉಪಕ್ರಮ
    ವೈದ್ಯಕೀಯ ಬೆಂಬಲ ಗುಂಪು, ಭಾರತೀಯ ಸಹಾಯವಾಣಿ, ಐಐಎಂ-ಕೆ ಸಮುದಾಯ ಬೆಂಬಲ (ಇನ್ನೂ ಹಲವು).`,
    q5: `ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ನೀವು ಎಷ್ಟು ಬಾರಿ ಪರಿಶೀಲಿಸುತ್ತೀರಿ?`,
    a5: `ನಾವು ನಿಯತಕಾಲಿಕವಾಗಿ ಮಾಹಿತಿಯನ್ನು ಪರಿಶೀಲಿಸುತ್ತೇವೆ, ಆದಾಗ್ಯೂ, ಡೇಟಾದ ಗಾತ್ರದಿಂದಾಗಿ
    ನೀವು ಹುಡುಕುತ್ತಿರುವುದನ್ನು ನೀವು ಕಂಡುಹಿಡಿಯದಿದ್ದಲ್ಲಿ ನಮ್ಮ ಕ್ಷಮೆಯಾಚನೆಯನ್ನು ಸ್ವೀಕರಿಸಿ. ನಾವು ಮಾಡುತ್ತೇವೆ
    ಡೇಟಾವನ್ನು ಸೇರಿಸಲು, ಪರಿಶೀಲಿಸಲು, ಸ್ವಚ್ clean ಗೊಳಿಸಲು ಮುಂದುವರಿಸಿ. ನಾವು ಡೇಟಾದಷ್ಟೇ ಉತ್ತಮವಾಗಿರುವುದರಿಂದ
    ನಾವು ಜೀವಗಳನ್ನು ಉಳಿಸಲು ಸಮರ್ಥರಾಗಿದ್ದೇವೆ ಎಂದು ಭಾವಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ಬೆಂಬಲವನ್ನು ಎದುರುನೋಡಬಹುದು. ಇವರಿಗೆ ಧನ್ಯವಾದಗಳು
    ಎಲ್ಲರೂ, ನೀವೆಲ್ಲರೂ ಇಲ್ಲದಿದ್ದರೆ ಸಾಧ್ಯವಾಗುತ್ತಿರಲಿಲ್ಲ.`,
    q6: `ನೀವು ಗಳಿಸದಿದ್ದಾಗ ಇದನ್ನು ಮಾಡಲು ನೀವು ಸಮಯ ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳನ್ನು ಏಕೆ ಹಾಕುತ್ತಿದ್ದೀರಿ
    ಅದರಿಂದ ಒಂದೇ ಪೆನ್ನಿ?`,
    a6: `ಸಹಯೋಗ, ಕ್ರೌಡ್‌ಸೋರ್ಸಿಂಗ್‌ನ ನಿಜವಾದ ಮನೋಭಾವವನ್ನು ನಾವು ನಂಬುತ್ತೇವೆ
   ಡೇಟಾದ ಪ್ರಜಾಪ್ರಭುತ್ವೀಕರಣ ಮತ್ತು ಸ್ವಯಂ ಸೇವಕ. ಜೀವಗಳನ್ನು ಉಳಿಸಲು ದಯವಿಟ್ಟು ನಮಗೆ ಬೆಂಬಲ ನೀಡಿ! ಸೇರಿ
   ನಮಗೆ.`,
    q7: `ನೀವು ನಮ್ಮೊಂದಿಗೆ ಹೇಗೆ ಸ್ವಯಂಸೇವಕರಾಗಬಹುದು?`,
    a7: ` ಪರಿಶೀಲಿಸಿದ ಡೇಟಾವನ್ನು ಸೇರಿಸಿ (ಅದು ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ) ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಡೇಟಾವನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು
    ಪರಿಶೀಲನೆ ಸ್ಥಿತಿಯನ್ನು ನವೀಕರಿಸಿ ಹಂಚಿಕೊಳ್ಳುವ ಮೂಲಕ ಇತರ ಪ್ಲ್ಯಾಟ್‌ಫಾರ್ಮ್‌ಗಳಲ್ಲಿ 1-1 ಜನರಿಗೆ ಸಹಾಯ ಮಾಡಿ
    ಕೋವಿಡ್‌ಫೈಯಿಂದ ಸಂಪರ್ಕ / ಲಿಂಕ್‌ಗಳು ಉತ್ಪನ್ನ ಸಂಬಂಧಿತ ವಿಚಾರಗಳನ್ನು ನವೀಕರಿಸಿ ಸಾಮಾಜಿಕವನ್ನು ನಿರ್ವಹಿಸಿ
    ಮಾಧ್ಯಮ & amp; ಮಾರ್ಕೆಟಿಂಗ್ ಕಾರ್ಯಾಚರಣೆಗಳು, ಸ್ವಯಂಸೇವಕರು, ಆನ್‌ಬೋರ್ಡಿಂಗ್ ಅನ್ನು ನಿರ್ವಹಿಸಿ
    ನೀವು ಸೇರಲು ಬಯಸುವ ತಂಡ ಅಥವಾ ಕರೆ ಬಂದಾಗಲೆಲ್ಲಾ ಪಿಚ್ ಮಾಡಲು ಅಂಟಿಕೊಳ್ಳಿ
    ಸಹಾಯಕ್ಕಾಗಿ!.`,
    q8: `ನೀವು ಪರಿಶೀಲಿಸಿದ ಡೇಟಾವನ್ನು ಹೊಂದಿದ್ದರೆ ಮತ್ತು ಕೋವಿಡ್ಫಿ ಡೇಟಾಬೇಸ್‌ಗೆ ಸೇರಿಸಲು ಬಯಸಿದರೆ ಏನು ಮಾಡಬೇಕು?`,
    a8: `ಡೇಟಾವನ್ನು ಸೇರಿಸುವ ಮೊದಲು ಇದನ್ನು ಓದಲು ನಿಮಗೆ ಶಿಫಾರಸು ಮಾಡಿ COVID ಅನ್ನು ಹೇಗೆ ಹಂಚಿಕೊಳ್ಳಬೇಕು ಎಂಬುದರ ಕುರಿತು ಸಲಹೆಗಳು
    ಸಂಪನ್ಮೂಲಗಳು ಆನ್‌ಲೈನ್`,
    q9: `ಬೃಹತ್ ಪರಿಶೀಲಿಸಿದ ಡೇಟಾವನ್ನು ಹೊಂದಿರಿ?`,
    a9: `ಒಂದು ವೇಳೆ ನೀವು ಡೇಟಾವನ್ನು ದೊಡ್ಡ ಪ್ರಮಾಣದಲ್ಲಿ, ಎಕ್ಸೆಲ್ ಸ್ವರೂಪದಲ್ಲಿ ಸೇರಿಸಲು ಬಯಸಿದರೆ, ಮತ್ತು ನೀವು ಇಲ್ಲ
    ಗಾಳಿಯಾಡಬಲ್ಲ ಅನುಕೂಲಕರವಾಗಿದೆ, ಅದನ್ನು Google ಶೀಟ್ ಅಥವಾ ಎಕ್ಸೆಲ್ ಸ್ವರೂಪದಲ್ಲಿ ನಮಗೆ ಕಳುಹಿಸಿ`,
    a91: `ಅಥವಾ ಎಲ್ಲಿಯಾದರೂ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ. ಮಾಹಿತಿಯನ್ನು ಸೇರಿಸಲು ನಮ್ಮ ತಂಡಕ್ಕೆ ಸೇರಿ.`,
    q10: `ನೀವು ನಮ್ಮಂತೆಯೇ ಇದೇ ರೀತಿಯ ಉಪಕ್ರಮವನ್ನು ಹೊಂದಿದ್ದೀರಾ? ಅಥವಾ ಒಂದನ್ನು ತಿಳಿಯಿರಿ?`,
    a10: `ಒಂದೇ ರೀತಿಯ ಡೇಟಾವನ್ನು ಹೊಂದಿರುವ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮದಲ್ಲಿ ನಿಮ್ಮ ಕಂಪನಿ, ಸ್ನೇಹಿತರು, ಕಾಲೇಜು ನಡೆಸುತ್ತದೆ.
    ಅವರನ್ನು ಸಂಪರ್ಕಿಸಿ, ನಮ್ಮ ಬಗ್ಗೆ ಮಾತನಾಡಿ. ಇತರ ಉಪಕ್ರಮಗಳನ್ನು ಪಡೆಯಲು ಅರ್ನೆಸ್ಟ್ ವಿನಂತಿ
    ನಾವು ಪರಿಹರಿಸುತ್ತಿರುವ ಸಮಸ್ಯೆಯಲ್ಲಿ ಬಲವರ್ಧನೆಯು ಮುಖ್ಯವಾದುದರಿಂದ ಸಹಕರಿಸಿ. ನಾವು ಕ್ರೆಡಿಟ್‌ಗಳನ್ನು ಹುಡುಕಬೇಡಿ,
    ಈ ಮಾಹಿತಿಯನ್ನು ತಲುಪಲು ನಾವು ಬಯಸುತ್ತೇವೆ. ನಮ್ಮ ಗುರಿ
    ಸರಿಯಾದ ಸಮಯದಲ್ಲಿ ಸರಿಯಾದ ಸಮಯದಲ್ಲಿ ಸರಿಯಾದ ಜನರಿಗೆ ಸರಿಯಾದ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಿ
    ವಿಧಾನ.`,
    q11: `ಹಕ್ಕುತ್ಯಾಗ`,
    a112: `COVID ಮಾಹಿತಿ ವೇದಿಕೆ `,
    a113: ` ಕೋವಿಡ್ ಎಫ್‌ವೈಐನ ಸ್ವಾಸ್ತ್ ಡಿಜಿಟಲ್ ಹೆಲ್ತ್ ಫೌಂಡೇಶನ್ (“ಸ್ವಾಸ್ತ್”) ರಚಿಸಿದ ವೇದಿಕೆಯಾಗಿದೆ
    ಮತ್ತು ಪರಿಶೀಲಿಸಿದ ಜನಸಮೂಹ ಮೂಲದ ತುರ್ತು ಸೇವೆಗಳನ್ನು ಒದಗಿಸಲು ಕರೋನಾಸಾಫ್ ನೆಟ್‌ವರ್ಕ್
    ಭಾರತದಾದ್ಯಂತ COVID-19 ತುರ್ತು ಪರಿಸ್ಥಿತಿಗಳಿಗಾಗಿ.`,
    a114: `COVID ಮಾಹಿತಿ ವೇದಿಕೆಯಲ್ಲಿ ಒದಗಿಸಲಾದ ಎಲ್ಲಾ ಡೇಟಾ ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತದೆ
    ಸ್ವಯಂಸೇವಕರ ಗುಂಪಿನಿಂದ ಅವರ ಅತ್ಯುತ್ತಮ ಸಾಮರ್ಥ್ಯಗಳು ಮತ್ತು ಸಂಸ್ಥೆಗಳಿಂದ
    COVID ನಲ್ಲಿ ಒದಗಿಸಲಾದ ಯಾವುದೇ ಡೇಟಾ / ಮಾಹಿತಿಯ ಎಲ್ಲಾ ಜವಾಬ್ದಾರಿಯನ್ನು ನಿರಾಕರಿಸು
    ಮಾಹಿತಿ ವೇದಿಕೆ. ಸಂಸ್ಥೆಗಳು ಯಾವುದೇ ಸಂದರ್ಭದಲ್ಲೂ ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ
    ಯಾವುದೇ ನೇರ, ಪರೋಕ್ಷ, ಪ್ರಾಸಂಗಿಕ, ಪರಿಣಾಮಕಾರಿ, ವಿಶೇಷ, ಶಿಕ್ಷಾರ್ಹ,
    ಅನುಕರಣೀಯ, ಅಥವಾ COVID ಮಾಹಿತಿಯ ಬಳಕೆಯಿಂದ ಉಂಟಾಗುವ ಯಾವುದೇ ಹಾನಿ
    ವೇದಿಕೆ. ಯಾವುದೇ ನಿರ್ದಿಷ್ಟ ಫಲಿತಾಂಶಗಳ ಸಂಸ್ಥೆಗಳಿಂದ ಯಾವುದೇ ಭರವಸೆ ಇಲ್ಲ
    COVID ಮಾಹಿತಿ ವೇದಿಕೆಯ ಬಳಕೆಯ. ಬಳಸುವ ಮೂಲಕ ಬಳಕೆದಾರರು ಅದನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ
    COVID ಮಾಹಿತಿ ವೇದಿಕೆಯಲ್ಲಿ ಒದಗಿಸಲಾದ ಯಾವುದೇ ಡೇಟಾ ಅಥವಾ ಮಾಹಿತಿಯು ಇರಬೇಕು
    ತಮ್ಮದೇ ಆದ ಅಪಾಯ ಮತ್ತು ಉಲ್ಲಂಘನೆ ಮತ್ತು ಸಂಸ್ಥೆಗಳು ಜವಾಬ್ದಾರರಾಗಿರುವುದಿಲ್ಲ
    ಅಂತಹ ಡೇಟಾ / ಮಾಹಿತಿಯನ್ನು ನೀಡಲು ಯಾವುದೇ ಪರಿಣಾಮಗಳು. COVID ಮಾಹಿತಿ
    ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಮಾಹಿತಿ ಉದ್ದೇಶಗಳಿಗಾಗಿ, ನಿರ್ವಹಿಸುವ ವೆಬ್‌ಸೈಟ್‌ಗಳಿಗೆ ಲಿಂಕ್‌ಗಳನ್ನು ಒದಗಿಸಬಹುದು
    ಇತರ ಘಟಕಗಳು ಮತ್ತು ವ್ಯಕ್ತಿಗಳು, ಆದರೆ ಯಾವುದೇ ಜವಾಬ್ದಾರಿಯನ್ನು ಅನುಮೋದಿಸುವುದಿಲ್ಲ ಅಥವಾ ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ
     ಅಂತಹ ವಿಷಯಕ್ಕಾಗಿ ಅಥವಾ ಅಂತಹ ವೆಬ್‌ಸೈಟ್‌ಗಳ ಬಳಕೆಗಾಗಿ. ಸೇರಿದಂತೆ ಯಾವುದೇ ರೀತಿಯ ಖಾತರಿ ಇಲ್ಲ
    ಆದರೆ ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಹಕ್ಕುಗಳನ್ನು ಉಲ್ಲಂಘಿಸದ ಖಾತರಿ ಕರಾರುಗಳಿಗೆ ಸೀಮಿತವಾಗಿಲ್ಲ,
    ಶೀರ್ಷಿಕೆ, ವ್ಯಾಪಾರದ ಸಾಮರ್ಥ್ಯ, ನಿರ್ದಿಷ್ಟ ಉದ್ದೇಶಕ್ಕಾಗಿ ಫಿಟ್‌ನೆಸ್ ಅನ್ನು ಗೌರವದಿಂದ ನೀಡಲಾಗುತ್ತದೆ
    ಈ ವೆಬ್‌ಸೈಟ್‌ನ ವಿಷಯಗಳಿಗೆ ಅಥವಾ ಇತರ ಇಂಟರ್ನೆಟ್ ಸಂಪನ್ಮೂಲಗಳಿಗೆ ಅದರ ಹೈಪರ್ಲಿಂಕ್‌ಗಳಿಗೆ.`
};
