export default {
    title: 'லைப்',
    description: 'சரிபார்க்கப்பட்ட கூட்டத்தின் மூல அவசர சேவைகள் அடைவு',
    oxygen: 'ஆக்ஸிஜன்',
    medicine: 'மருந்து',
    hospital: 'மருத்துவமனை',
    ambulance: 'அவசர ஊர்தி',
    helpline: 'தொலைபேசி',
    plasma: 'பிளாஸ்மா',
    vaccine: 'தடுப்பூசி',
    searchPlaceholder: 'மாநிலத்தில் அல்லது மாவட்டத்தில் கிடைப்பதைத் தேடுங்கள்',
    state: 'மாநிலம்',
    district: 'மாவட்டம்',
    covid19Stats: 'COVID-19 புள்ளிவிவரம்',
    totalHospitals: 'மொத்த மருத்துவமனைகள்',
    verified: 'சரிபார்க்கப்பட்டது',
    totalAmbulace: 'மொத்த அவசர ஊர்தி',
    oxygenRequirements: 'ஆக்ஸிஜன் செறிவு தேவைகள்',
    contributeData: 'தரவை பங்களிக்க',
    campaigns: 'பிரச்சாரங்கள்',
    learn: 'அறிய',
    partnerWithUs: 'எங்களுடன் சேர',
    curatedBy: 'நிர்வகிக்கப்பது',
    supportedBy: 'உதவியவா்',
    poweredBy: 'இயக்குபவர்',
    github: 'கிட்ஹப்',
    database: 'தரவுத்தளம்',
    about: 'பற்றி',
    infoOnCovid: `COVID-19 பற்றிய தகவல்`
};
