export default {
    text: `आजच्या घडीला जगातील सर्वोच्च दैनंदिन घटनांसह भारतामध्ये तीव्र दुसर्‍या वेगाने जात आहे. कोविड - 19 against च्या विरोधात लढा देण्यासाठी रुग्णालये आणि आरोग्य सेवा पुरवठा करणारे संसाधनांचा अभाव आहे आणि जमिनीवरील परिस्थिती भयानक आहे. कोविडने बाधित रूग्णांवर टीका करण्यासाठी ऑक्सिजनची गंभीरपणे आवश्यकता असते, त्यापैकी बर्‍याच जणांना ऑक्सिजनच्या अस्थिरतेमुळे रुग्णालयात दाखल करावे लागते. तथापि, भारतातील रूग्णालयात ऑक्सिजन उपलब्धतेची तीव्र कमतरता आहे. Oxygen concentrators are alternate devices to oxygen cylinders – while cylinders contain a finite amount of oxygen supply, a concentrator recycles oxygen from the air and delivers it to the patient. They can be used to manage patient requirements without looking for external sources of oxygen.

एससीटी ग्रांटसमवेत स्वस्थ डिजिटल हेल्थ फाउंडेशन (स्वास्थ.अॅप) ऑक्सिजन सांद्रता मिळवून कोविड प्रतिसाद व दीर्घकालीन आरोग्य-यंत्रणा बळकट करण्यासाठी दुर्गम भागातील काळजी घेणा-या स्तरावरील रुग्णालयांमध्ये पोहोचवण्याचा विचार करीत आहे. हे ऑक्सिजन केंद्रे हजारो लोकांचे जीवन वाचवू शकतात आणि रुग्णालयांना आवश्यक ते संसाधने वाढवू शकतात. त्यांचा उपयोग बाह्यरुग्णांवर उपचार करण्यासाठी, रुग्णालयाच्या बेडवरील भार कमी करणे आणि रुग्णांना होणारा तणाव कमी करण्यासाठी देखील केला जाऊ शकतो.
• उच्च फ्लो कॉन्सेन्टरची किंमत 85000 रुपये आहे आणि गंभीर किंवा गंभीर आजाराने 550 .
• कमी प्रवाह केंद्राची किंमत 45000 रुपये आहे आणि सौम्य ते मध्यम आजार असलेल्या 900 पर्यंत रूग्णांवर उपचार करू शकतात

Swasth या प्रयत्नास पाठिंबा देण्यासाठी निधी गोळा करीत आहे. येथे देणगी देऊन योगदान द्या: https://www.impactguru.com/fundraiser/oxygen
Oxygen concentrators चे वितरण आणि वाटप ही पद्धत आणि इफेक्ट मेट्रिक्ससह उघडपणे प्रकाशित केलेली पारदर्शक प्रक्रिया असेल जेणेकरून आपल्या जीवनाचे जतन करण्याच्या योगदानाबद्दल आपल्याला खात्री मिळेल.
आपण सीएसआर बजेट उपयोजित करण्याचा विचार करणारी एखादी कंपनी असल्यास किंवा एखादी देणगी देण्यास इच्छुक वैयक्तिक देणगीदार असाल तर कृपया आम्हाला येथे ईमेल करा shubha@swasthapp.com. आम्ही आपल्या समर्थनाची अपेक्षा करतो.`
};
