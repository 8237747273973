export default {
  text: `இன்றைய நிலவரப்படி, உலகின் மிக உயர்ந்த தினசரி நிகழ்வுகளுடன் இந்தியா கடுமையான இரண்டாவது அலைக்கு ஆளாகியுள்ளது.
    COVID-19 க்கு எதிரான போராட்டத்தில் மருத்துவமனைகள் மற்றும் சுகாதார வழங்குநர்கள் வளங்களை இழந்துவிட்டனர், மேலும் நிலத்தின் நிலைமை மோசமாக உள்ளது.
    COVID நோயால் பாதிக்கப்பட்ட நோயாளிகளுக்கு சிகிச்சையளிக்க ஆக்ஸிஜன் விமர்சன ரீதியாக தேவைப்படுகிறது, அவர்களில் பலருக்கு ஆக்ஸிஜன் அளவு ஏற்ற இறக்கத்தால் மருத்துவமனையில் அனுமதிக்கப்பட வேண்டும்.
    இருப்பினும், இந்தியாவில் உள்ள மருத்துவமனைகளில் ஆக்ஸிஜன் கிடைப்பதில் கடுமையான பற்றாக்குறை உள்ளது.
    ஆக்ஸிஜன் செறிவூட்டிகள் ஆக்ஸிஜன் சிலிண்டர்களுக்கு மாற்று சாதனங்கள் - சிலிண்டர்களில் வரையறுக்கப்பட்ட அளவு ஆக்ஸிஜன் சப்ளை இருக்கும்போது, ​​ஒரு செறிவு காற்றில் இருந்து ஆக்ஸிஜனை மறுசுழற்சி செய்து நோயாளிக்கு வழங்குகிறது.
    ஆக்ஸிஜனின் வெளிப்புற மூலங்களைத் தேடாமல் நோயாளியின் தேவைகளை நிர்வகிக்க அவற்றைப் பயன்படுத்தலாம்.
    ACT மானியங்களுடன் ஸ்வஸ்த் டிஜிட்டல் ஹெல்த் பவுண்டேஷன் (ஸ்வஸ்த்.ஆப்) ஆக்ஸிஜன் செறிவூட்டிகளை வாங்குவதற்கும், COVID பதில் மற்றும் நீண்டகால சுகாதார-அமைப்பு வலுப்படுத்துதலுக்காக தொலைதூர பகுதிகளில் உள்ள பராமரிப்பு நிலைகளில் உள்ள மருத்துவமனைகளுக்கு அவற்றை அனுப்பவும் முயல்கிறது. இந்த ஆக்ஸிஜன் செறிவூட்டிகள் பல ஆயிரக்கணக்கான உயிர்களைக் காப்பாற்ற முடியும் மற்றும் மருத்துவமனைகளுக்கு மிகவும் தேவையான வளங்களை உயர்த்தும். வெளிநோயாளிகளுக்கு சிகிச்சையளிக்கவும், மருத்துவமனை படுக்கைகளின் சுமையை குறைக்கவும், நோயாளிகள் எதிர்கொள்ளும் மன அழுத்தத்தை குறைக்கவும் அவை பயன்படுத்தப்படலாம்.
    • அதிக ஓட்டம் செறிவு ரூ .85000 செலவாகும் மற்றும் கடுமையான அல்லது சிக்கலான நோயால் பாதிக்கப்பட்ட 550 நோயாளிகளுக்கு சிகிச்சையளிக்கிறது.
    • Flow குறைந்த ஓட்டம் செறிவு ரூ .45000 செலவாகும், மேலும் லேசான மற்றும் மிதமான நோயால் பாதிக்கப்பட்ட 900 நோயாளிகளுக்கு சிகிச்சையளிக்க முடியும்
    இந்த முயற்சியை ஆதரிக்க ஸ்வஸ்த் நிதி திரட்டுகிறார்.
    நன்கொடை அளிப்பதன் மூலம் பங்களிக்கவும்: https://www.impactguru.com/fundraiser/oxygen செறிவூட்டிகளின் விநியோகம் மற்றும் ஒதுக்கீடு வெளிப்படையாக வெளியிடப்பட்ட முறை மற்றும் தாக்க அளவீடுகளுடன் ஒரு வெளிப்படையான செயல்முறையாக இருக்கும், எனவே உங்கள் பங்களிப்புகளை உயிரைக் காப்பாற்றுவதை நீங்கள் உறுதிப்படுத்திக் கொள்ளலாம்.
    நீங்கள் ஒரு சமூக பொறுப்புணர்வு பட்ஜெட்டை பயன்படுத்த விரும்பும் நிறுவனம் அல்லது பங்களிக்க விரும்பும் ஒரு தனிப்பட்ட நன்கொடையாளர் என்றால், தயவுசெய்து எங்களுக்கு shubha@swasthapp.com இல் மின்னஞ்சல் அனுப்புங்கள். உங்கள் ஆதரவை எதிர்பார்க்கிறோம் .`
};
