export default {
    question: `ನೀವು ಪರಿಶೀಲಿಸಿದ ಡೇಟಾವನ್ನು ಹೊಂದಿದ್ದೀರಾ ಮತ್ತು ಡೇಟಾಬೇಸ್‌ಗೆ ಸೇರಿಸಲು ಬಯಸುವಿರಾ?`,
    sourceType: `ಮೂಲ, ಪ್ರಕಾರ, ವಿತರಣೆ`,
    medicineInjection: `ಮೆಡಿಸಿನ್ / ಇಂಜೆಕ್ಷನ್ / ಕೆಮಿಸ್ಟ್`,
    medicineDes: `ವಿತರಕ, ವರ್ಗ, ವಿಳಾಸ`,
    hospitalDes: `ಜಿಲ್ಲೆ, ವಿಳಾಸ, ಸಂಪರ್ಕ`,
    helplineDes: `ವರ್ಗ, ಫೋನ್`,
    doctorTele: `ವೈದ್ಯರು / ಟೆಲಿಮೆಡಿಸಿನ್ / ಸಮಾಲೋಚನೆ`,
    doctorDes: `ವಿಶೇಷತೆ, ನಗರ, ಸಂಪರ್ಕ`,
    supportGroup: `ಬೆಂಬಲ ಗುಂಪುಗಳು (ವಾಟ್ಸಾಪ್, ಟೆಲಿಗ್ರಾಮ್ ಇತ್ಯಾದಿ)`,
    supportDes: `ಪ್ಲಾಟ್‌ಫಾರ್ಮ್, ವರ್ಗ, ಸಂಪರ್ಕ`,
    applyVol: `ಸ್ವಯಂಸೇವಕರಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ`,
    applyDes: `ಕೌಶಲ್ಯಗಳು, ಸಂಪರ್ಕಿಸಿ`,
    govtContact: `ಸರ್ಕಾರದ ಸಂಪರ್ಕ`,
    govtDes: `ಮೂಲ, ನಗರ, ಸಂಪರ್ಕ`,
    fillTheForm: `ಫಾರ್ಮ್ ಅನ್ನು ಭರ್ತಿ ಮಾಡಿ`
};
