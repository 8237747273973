export default {
    title: 'लाइफ़',
    description: 'सत्यापित क्रोड सोर्सिड इमर्जन्सी सर्व्हिसेस निर्देशिका',
    oxygen: 'ऑक्सिजन',
    medicine: 'औषध',
    hospital: 'रुग्णालय',
    ambulance: 'रुग्णवाहिका',
    helpline: 'हेल्पलाइन',
    plasma: 'प्लाझ्मा',
    vaccine: 'देवीची लस',
    searchPlaceholder: 'राज्य किंवा जिल्ह्यात उपलब्धतेचा शोध घ्या',
    state: 'राज्य',
    district: 'जिल्हा',
    covid19Stats: 'कोविड 19 आकडेवारी',
    totalHospitals: 'एकूण रुग्णालये',
    verified: 'सत्यापित',
    totalAmbulace: 'एकूण रुग्णवाहिका',
    oxygenRequirements: 'ऑक्सिजन आवश्यकता',
    contributeData: 'सत्यापित डेटाचे योगदान द्या',
    campaigns: 'मोहिमा',
    learn: 'अधिक जाणून घ्या',
    partnerWithUs: 'आमच्यासह भागीदार',
    curatedBy: 'क्युरेटेड बाय',
    supportedBy: 'समर्थित',
    poweredBy: 'द्वारा समर्थित',
    github: 'गीथब',
    database: 'डेटाबेस',
    about: 'आमच्याबद्दल',
    infoOnCovid: `COVID-19 वर माहिती`,
    select: 'निवडा',
    selectResource: 'स्त्रोत निवडा',
    startSearchingAmong: 'आपापसांत शोध सुरू करा',
    resources: 'संसाधने',
    disclaimer: 'अस्वीकरण',
    dataNotOwned: 'डेटा आमच्या मालकीचा किंवा तयार केलेला नाही',
    knowMore: 'अधिक जाणून घ्या'
};
