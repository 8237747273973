export default {
    question: `നിങ്ങൾക്ക് സ്ഥിരീകരിച്ച ഡാറ്റ ഉണ്ടോ ഒപ്പം ഡാറ്റാബേസിലേക്ക് ചേർക്കാൻ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    sourceType: `ഉറവിടം, തരം, ഡെലിവറി`,
    medicineInjection: `മരുന്ന്/ഇൻജെക്ഷൻ/കെമിസ്റ്`,
    medicineDes: `വിതരണക്കാരൻ, വിഭാഗം, വിലാസം`,
    hospitalDes: `ജില്ല, വിലാസം, ബന്ധപ്പെടുക`,
    helplineDes: `വിഭാഗം, ഫോൺ`,
    doctorTele: `ഡോക്ടർ / ടെലിമെഡിസിൻ / കൺസൾട്ടേഷൻ`,
    doctorDes: `സ്പെഷ്യലൈസേഷൻ, നഗരം, ബന്ധപ്പെടുക`,
    supportGroup: `പിന്തുണാ ഗ്രൂപ്പുകൾ (വാട്ട്‌സ്ആപ്പ്, ടെലിഗ്രാം തുടങ്ങിയവ)`,
    supportDes: `പ്ലാറ്റ്ഫോം, വിഭാഗം, ബന്ധപ്പെടുക`,
    applyVol: `സന്നദ്ധപ്രവർത്തകരായി അപേക്ഷിക്കുക`,
    applyDes: `കഴിവുകൾ, ബന്ധപ്പെടുക`,
    govtContact: `സർക്കാർ കോൺടാക്റ്റ്`,
    govtDes: `ഉറവിടം, നഗരം, ബന്ധപ്പെടുക`,
    fillTheForm: `ഫോം പൂരിപ്പിക്കുക`
};
