export default {
    text: `آج تک ہندوستان دنیا کے سب سے زیادہ روزمرہ کے واقعات کے ساتھ دوسری لہر سے گزر رہا ہے۔کوویڈ-19 کے خلاف جنگ میں اسپتالوں اور صحت کی دیکھ بھال فراہم کرنے والوں کے وسائل ختم ہو رہے ہیں اور زمینی صورتحال سنگین ہے۔کوویڈ سے متاثرہ مریضوں کے علاج کے لئے آکسیجن کی اشد ضرورت ہوتی ہے، جن میں سے بہت سے کو آکسیجن کی سطح میں اتار چڑھاؤ کی وجہ سے اسپتال میں داخل ہونے کی ضرورت ہوتی ہے۔تاہم ہندوستان کے اسپتالوں میں آکسیجن کی دستیابی کی شدید قلت ہے۔آکسیجن مرتکز آکسیجن سلنڈروں کے متبادل آلات ہیں - جبکہ سلنڈروں میں آکسیجن کی فراہمی کی محدود مقدار ہوتی ہے، ایک مرتکز ہوا سے آکسیجن کو ری سائیکل کرتا ہے اور اسے مریض تک پہنچاتا ہے۔انہیں آکسیجن کے بیرونی ذرائع کی تلاش کے بغیر مریضوں کی ضروریات کا انتظام کرنے کے لئے استعمال کیا جاسکتا ہے۔

  سواستھ ڈیجیٹل ہیلتھ فاؤنڈیشن (Swasth.app) اے سی ٹی گرانٹس کے ساتھ آکسیجن مرتکز افراد کی خریداری اور انہیں کوویڈ کے ردعمل اور طویل مدتی صحت کے نظام کو مضبوط بنانے کے لئے دور دراز علاقوں میں دیکھ بھال کی سطح وں پر اسپتالوں میں بھیجنے کی کوشش کر رہی ہے۔یہ آکسیجن مرتکز کئی ہزار جانیں بچا سکتے ہیں اور اسپتالوں کے لئے انتہائی ضروری وسائل کو تقویت دے سکتے ہیں۔انہیں بیرونی مریضوں کے علاج کے لئے بھی استعمال کیا جاسکتا ہے، اسپتال کے بستروں پر بوجھ کو کم کیا جاسکتا ہے اور مریضوں کو درپیش دباؤ کو کم کیا جاسکتا ہے۔
  ایک ہائی فلو مرتکز کی قیمت 85000 روپے ہے اور شدید یا سنگین بیماری کے 550 مریضوں کا علاج کرتا ہے۔ •
  -کم بہاؤ مرتکز کی قیمت 45000 روپے ہے اور یہ ہلکی سے اعتدال پسند بیماری کے 900 مریضوں کا علاج کر سکتا ہے •

  سواستھ اس کوشش کی حمایت کے لئے چندہ اکٹھا کر رہا ہے۔ براہ کرم عطیہ دے کر حصہ ڈالیں: https://www.impactguru.com/fundraiser/oxygen
  مرتکز کی تقسیم اور مختص ایک شفاف عمل ہوگا جس میں کھلے عام شائع ہونے والے طریقہ کار اور اثر میٹرکس ہوں گے تاکہ آپ کو زندگیاں بچانے میں اپنی شراکت کی یقین دہانی کرائی جا سکے۔
  اگر آپ سی ایس آر بجٹ تعینات کرنے کی کوشش کر رہے ہیں یا کوئی انفرادی عطیہ دہندہ حصہ ڈالنے کے لئے تیار ہیں تو براہ کرم ہمیں shubha@swasthapp.com پر ای میل کریں۔ہم آپ کی حمایت کے منتظر ہیں۔`
};
