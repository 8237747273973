export default {
    title: 'ಜೀವನ',
    description: 'ಪರಿಶೀಲಿಸಲ್ಪಟ್ಟ ಗುಂಪುಆಧಾರಿತ ತುರ್ತು ಸೇವೆಗಳ ಕೈಪಿಡಿ',
    oxygen: 'ಆಮ್ಲಜನಕ',
    medicine: 'ಔಷಧಿ',
    hospital: 'ಆಸ್ಪತ್ರೆ',
    ambulance: 'ಅಂಬುಲೆನ್ಸ್',
    helpline: 'ಸಹಾಯವಾಣಿ',
    plasma: 'ಪ್ಲಾಸ್ಮ',
    vaccine: 'ಲಸಿಕೆ',
    searchPlaceholder: 'ರಾಜ್ಯ ಅಥವಾ ಜಿಲ್ಲೆಯಲ್ಲಿ ಲಭ್ಯತೆಯ ಬಗ್ಗೆ ಹುಡುಕುವ',
    state: 'ರಾಜ್ಯ',
    district: 'ಜಿಲ್ಲೆಯ',
    covid19Stats: 'ಕೋವಿಡ್ 19 ಅಂಕಿ-ಅಂಶ',
    totalHospitals: 'ಒಟ್ಟು ಆಸ್ಪತ್ರೆಗಳು',
    verified: 'ಪರಿಶೀಲಿಸಿದೆ',
    totalAmbulace: 'ಒಟ್ಟು ಅಂಬುಲೆನ್ಸ್ ಗಳು',
    oxygenRequirements: 'ಅವಶ್ಯವಿರುವ ಆಮ್ಲಜನಕ',
    contributeData: 'ಪರಿಶೀಲಿಸಲ್ಪಟ್ಟ ಅಂಕಿ-ಅಂಶ ಒದಗಿಸು',
    campaigns: 'ಆಂದೋಲನಗಳು',
    learn: 'ತಿಳಿಯಿರಿ',
    partnerWithUs: 'ನಮ್ಮ ಜೊತೆಗೂಡಿ',
    curatedBy: 'ಪರಿಪಾರಕರು',
    supportedBy: 'ಬೆಂಬಲಿಗರು',
    poweredBy: 'ಸಮರ್ಥಕರು',
    github: 'ಗೀಥಬ್',
    database: 'ಮಾಹಿತಿ ಆಧಾರ',
    infoOnCovid: `COVID-19 ನಲ್ಲಿ ಮಾಹಿತಿ`
};
