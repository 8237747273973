export default {
    head: 'ਸਾਡੇ ਬਾਰੇ',
    q1: 'ਕੀ ਤੁਸੀਂ ਅਧਿਕਾਰੀ ਹੋ?',
    a1: 'ਨਹੀਂ',
    q2: 'ਤੁਹਾਡੇ ਸਰੋਤ ਕੀ ਹਨ? ਇਸ ਪ੍ਰੋਜੈਕਟ ਲਈ ਡੇਟਾ ਕਿਵੇਂ ਇਕੱਤਰ ਕੀਤਾ ਜਾਂਦਾ ਹੈ?',
    a21: ` ਅਸੀਂ ਡੇਟਾ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਕਈ ਸਰੋਤ ਦੀ ਵਰਤੋਂ ਕਰ ਰਹੇ ਹਾਂ. ਸਾਡੀ ਅੰਦਰੂਨੀ ਵਾਲੰਟੀਅਰ ਟੀਮ ਸਕਾoutsਟ
  ਸਰਕੂਲਰ, ਨੋਟਿਸ, ਟਵੀਟ, ਹੋਰ ਡਾਟਾਬੇਸ, ਵੈਬਸਾਈਟਾਂ ਤੋਂ ਡਾਟਾ ਲਈ. ਅਸੀਂ ਵੀ
  ਭੀੜ-ਸਰੋਤ ਵਾਲੇ ਚੈਨਲਾਂ ਤੋਂ ਮਹੱਤਵਪੂਰਣ ਡੇਟਾ ਪ੍ਰਾਪਤ ਕਰੋ - ਦੁਆਰਾ ਪੇਸ਼ ਕੀਤੇ ਫਾਰਮ
  ਆਮ ਲੋਕ. ਸਮਾਨ ਸੂਚੀ ਬਣਾਉਣ ਵਿੱਚ ਲੱਗੇ ਭਾਈਵਾਲ ਪਹਿਲਕਦਮੀਆਂ ਰਲ ਗਈਆਂ ਹਨ
  ਸਾਡੇ ਨਾਲ ਉਹਨਾਂ ਦੀ ਪ੍ਰਮਾਣਿਤ ਜਾਣਕਾਰੀ ਨੂੰ ਸਾਂਝਾ ਕਰਨ ਲਈ ਯਤਨ.`,
    a22: `ਡੇਟਾ ਨੂੰ ਵਾਲੰਟੀਅਰਾਂ ਦੇ ਇੱਕ ਸਮੂਹ ਦੁਆਰਾ ਪ੍ਰਮਾਣਿਤ ਕੀਤਾ ਜਾਂਦਾ ਹੈ ਅਤੇ ਇੱਕ ਏਅਰਟੇਬਲ ਵਿੱਚ ਪ੍ਰਕਾਸ਼ਤ ਕੀਤਾ ਜਾਂਦਾ ਹੈ
  ਸ਼ੀਟ ਅਤੇ ਇੱਕ ਏਪੀਆਈ. ਏਪੀਆਈ ਸਾਰੇ ਲਈ ਉਪਲਬਧ ਹੈ `,
    a23: `| ਅਸੀਂ ਇਸ ਨੂੰ ਪਸੰਦ ਕਰਾਂਗੇ ਜੇ ਤੁਸੀਂ ਇਸ ਡੇਟਾ ਨੂੰ ਇਸ ਵਾਇਰਸ ਦੇ ਵਿਰੁੱਧ ਲੜਾਈ ਵਿੱਚ ਵਰਤ ਸਕਦੇ ਹੋ|`,
    q3: `ਮੈਨੂੰ ਇਸਦੇ ਲਈ ਡੇਟਾ ਕਿੱਥੇ ਮਿਲ ਸਕਦਾ ਹੈ?`,
    a31: `ਸਾਰੇ ਡਾਟੇ ਦਾ ਸਰੋਤ ਹੈ`,
    a32: `'ਤੇ ਕੋਈ ਮੁੱਦਾ ਦਾਇਰ ਕਰੋ `,
    a33: `ਜੇ ਤੁਹਾਡੇ ਕੋਲ ਫੀਡਬੈਕ ਹਨ / ਬੱਗ ਰਿਪੋਰਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ.`,
    q4: `ਤੁਸੀ ਕੌਨ ਹੋ?`,
    a4: `ਅਸੀਂ ਸਮਰਪਿਤ ਵਲੰਟੀਅਰਾਂ ਦਾ ਸਮੂਹ ਹਾਂ ਜੋ ਆਉਣ ਵਾਲੇ ਡਾਟੇ ਨੂੰ ਸਹੀ ਅਤੇ ਤਸਦੀਕ ਕਰਦੇ ਹਨ
  ਕਈ ਸਰੋਤਾਂ ਤੋਂ. ਕੋਵਿਡਫਾਈਆਈ - ਏ ਦੇ ਰੂਪ ਵਿਚ ਕੋਵਿਡ ਸਰੋਤਾਂ ਨੂੰ ਇਕੱਤਰ ਕਰਦਾ ਹੈ
  ਡਾਇਰੈਕਟਰੀ ਰਿਪੋਜ਼ਟਰੀ ਜਾਣਕਾਰੀ ਦੀ ਭਾਲ ਵਿੱਚ ਲੋਕਾਂ ਦੀ ਸਹਾਇਤਾ ਲਈ. ਅਸੀਂ ਕਦੇ ਇਕੱਠੇ ਨਹੀਂ ਕਰਦੇ ਜਾਂ
  ਮਰੀਜ਼ਾਂ ਦੇ ਸੰਬੰਧ ਵਿੱਚ ਕੋਈ ਵਿਅਕਤੀਗਤ ਤੌਰ ਤੇ ਪਛਾਣਨ ਯੋਗ ਡੇਟਾ ਬੇਨਕਾਬ ਕਰੋ. ਅਸੀਂ ਨਹੀਂ ਕਰਦੇ
  1-ਆਨ -1 ਕੇਸਾਂ ਦਾ ਨਿਪਟਾਰਾ ਕਰਨਾ. ਇਹ ਸਹਿਯੋਗ ਦਾ ਉਤਪਾਦ ਹੈ - ਕੋਵਿਡਫੀਈ, ਏ
  ਸਵਸਥ ਪਹਿਲਕਦਮੀ, ਕੋਰੋਨਾਸਾਫੇ ਦੀ ਸਹਾਇਤਾ ਨਾਲ, ਸਟੀਫਨ ਵਾਲੰਟੀਅਰਜ਼, ਪੀਆਈ ਇੰਡੀਆ,
  ਮੈਡੀਕਲ ਸਹਾਇਤਾ ਸਮੂਹ, ਭਾਰਤੀ ਹੈਲਪਲਾਈਨ, ਆਈਆਈਐਮ-ਕੇ ਕਮਿ communityਨਿਟੀ ਸਹਾਇਤਾ (ਹੋਰ ਬਹੁਤ ਸਾਰੇ).`,
    q5: `ਕਿੰਨੀ ਵਾਰ ਤੁਸੀਂ ਆਪਣੀ ਜਾਣਕਾਰੀ ਦੀ ਤਸਦੀਕ ਕਰਦੇ ਹੋ?`,
    a5: `ਕ੍ਰਿਪਾ ਕਰਕੇ, ਅਸੀਂ ਸਮੇਂ ਸਮੇਂ ਤੇ ਜਾਣਕਾਰੀ ਦੀ ਤਸਦੀਕ ਕਰਦੇ ਹਾਂ, ਪਰ, ਡੇਟਾ ਦੇ ਅਕਾਰ ਦੇ ਕਾਰਨ, ਕਿਰਪਾ ਕਰਕੇ
  ਸਾਡੀ ਮਾਫੀ ਮੰਗੋ ਜੇ ਤੁਹਾਨੂੰ ਉਹ ਨਹੀਂ ਮਿਲਦਾ ਜਿਸ ਦੀ ਤੁਸੀਂ ਭਾਲ ਕਰ ਰਹੇ ਸੀ. ਅਸੀਂ ਕਰਾਂਗੇ
  ਡੇਟਾ ਨੂੰ ਜੋੜਨਾ, ਪ੍ਰਮਾਣਿਤ ਕਰਨਾ, ਸਾਫ਼ ਕਰਨਾ ਜਾਰੀ ਰੱਖੋ. ਕਿਉਂਕਿ ਅਸੀਂ ਸਿਰਫ ਡੇਟਾ ਜਿੰਨੇ ਚੰਗੇ ਹਾਂ
  ਉਮੀਦ ਹੈ ਕਿ ਅਸੀਂ ਜਾਨਾਂ ਬਚਾਉਣ ਦੇ ਯੋਗ ਹੋਵਾਂਗੇ. ਆਪਣੇ ਸਮਰਥਨ ਦੀ ਉਮੀਦ ਕਰੋ. ਧੰਨਵਾਦ
  ਹਰ ਕੋਈ, ਤੁਹਾਡੇ ਸਾਰਿਆਂ ਤੋਂ ਬਿਨਾਂ ਸੰਭਵ ਨਹੀਂ ਹੁੰਦਾ.`,
    q6: `ਤੁਸੀਂ ਮੁੰਡਿਆਂ ਨੂੰ ਅਜਿਹਾ ਕਰਨ ਲਈ ਸਮਾਂ ਅਤੇ ਸਰੋਤ ਕਿਉਂ ਪਾ ਰਹੇ ਹੋ ਜਦੋਂ ਕਿ ਏ ਪ੍ਰਾਪਤ ਨਾ ਕਰੋ
  ਇਸ ਵਿਚੋਂ ਇਕ ਪੈਸਾ?`,
    a6: `ਅਸੀਂ ਸਹਿਯੋਗ, ਭੀੜ ਸੋਰਸਿੰਗ, ਦੀ ਅਸਲ ਭਾਵਨਾ ਵਿੱਚ ਵਿਸ਼ਵਾਸ਼ ਰੱਖਦੇ ਹਾਂ
  ਡੇਟਾ ਦਾ ਡੈਮੋਕਰੇਟਾਈਜ਼ੇਸ਼ਨ, ਅਤੇ ਸਵੈ-ਸੇਵੀ. ਕ੍ਰਿਪਾ ਕਰਕੇ ਜਾਨਾਂ ਬਚਾਉਣ ਲਈ ਸਾਡੀ ਸਹਾਇਤਾ ਕਰੋ! ਜੁੜੋ
  ਸਾਨੂੰ.`,
    q7: `ਤੁਸੀਂ ਸਾਡੇ ਨਾਲ ਵਾਲੰਟੀਅਰ ਕਿਵੇਂ ਹੋ ਸਕਦੇ ਹੋ?`,
    a7: ` ਪ੍ਰਮਾਣਿਤ ਡੇਟਾ ਸ਼ਾਮਲ ਕਰੋ (ਜੋ ਕਿ ਪਹਿਲਾਂ ਮੌਜੂਦ ਨਹੀਂ ਹੈ) ਮੌਜੂਦਾ ਡਾਟਾ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ ਅਤੇ
  ਅਪਡੇਟ ਵੈਰੀਫਿਕੇਸ਼ਨ ਸਟੇਟਸ ਲੋਕਾਂ ਨੂੰ 1-1 ਨਾਲ ਦੂਜੇ ਪਲੇਟਫਾਰਮਾਂ ਤੇ ਸਾਂਝਾ ਕਰਕੇ ਮਦਦ ਕਰੋ
  ਉਤਪਾਦ / ਸਬੰਧਤ ਵਿਚਾਰਾਂ 'ਤੇ ਕੋਵਿਡਫੀਈ ਇਨੋਵੇਟ ਤੋਂ ਸੰਪਰਕ / ਲਿੰਕ ਸੋਸ਼ਲ ਹੈਂਡਲ ਕਰੋ
  ਮੀਡੀਆ & amp; ਮਾਰਕੀਟਿੰਗ ਕਾਰਜਾਂ, ਵਲੰਟੀਅਰਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ, ਜਹਾਜ਼ ਤੇ ਫੈਸਲਾ ਕਰੋ ਕਿ ਕਿਹੜੇ
  ਉਹ ਟੀਮ ਜਿਸ ਵਿਚ ਤੁਸੀਂ ਸ਼ਾਮਲ ਹੋਣਾ ਚਾਹੁੰਦੇ ਹੋ ਜਾਂ ਜਦੋਂ ਵੀ ਕੋਈ ਕਾਲ ਆਉਂਦੀ ਹੈ ਤਾਂ ਅੰਦਰ ਚਲੇ ਜਾਓ
  ਮਦਦ ਲਈ!.`,
    q8: `ਕੀ ਕਰਨਾ ਹੈ ਜੇ ਤੁਹਾਡੇ ਕੋਲ ਪ੍ਰਮਾਣਿਤ ਡੇਟਾ ਹੈ ਅਤੇ ਕੋਵਿਦਫੀ ਡੇਟਾਬੇਸ ਵਿਚ ਸ਼ਾਮਲ ਕਰਨਾ ਹੈ?`,
    a8: `COVID ਨੂੰ ਕਿਵੇਂ ਸਾਂਝਾ ਕਰਨਾ ਹੈ ਬਾਰੇ ਡਾਟਾ ਸੁਝਾਅ ਜੋੜਨ ਤੋਂ ਪਹਿਲਾਂ ਤੁਹਾਨੂੰ ਇਸ ਨੂੰ ਪੜ੍ਹਨ ਦੀ ਸਿਫਾਰਸ਼ ਕਰਦੇ ਹਨ
  ਸਰੋਤ .ਨਲਾਈਨ`,
    q9: `ਕੀ ਥੋਕ ਵੈਰੀਫਾਈਡ ਡੇਟਾ ਹੈ?`,
    a9: `ਜੇ ਤੁਸੀਂ ਡੇਟਾ ਨੂੰ ਥੋਕ ਵਿਚ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹੋ, ਐਕਸਲ ਫਾਰਮੈਟ ਵਿਚ, ਅਤੇ ਤੁਸੀਂ ਨਹੀਂ ਹੋ
  ਏਅਰਟੈਬਲ ਦੇ ਨਾਲ ਆਰਾਮਦਾਇਕ, ਇਸ ਨੂੰ ਗੂਗਲ ਸ਼ੀਟ ਵਿਚ ਜਾਂ ਐਕਸਲ ਫਾਰਮੈਟ ਵਿਚ ਸਾਨੂੰ ਭੇਜੋ`,
    a91: `ਜਾਂ ਕਿਤੇ ਵੀ ਸਾਡੇ ਤੱਕ ਪਹੁੰਚ ਕਰੋ. ਜਾਣਕਾਰੀ ਸ਼ਾਮਲ ਕਰਨ ਲਈ ਸਾਡੀ ਟੀਮ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਵੋ.`,
    q10: `ਕੀ ਤੁਸੀਂ ਵੀ ਸਾਡੇ ਵਰਗਾ ਪਹਿਲ ਹੋ? ਜਾਂ ਇਕ ਜਾਣੋ?`,
    a10: `ਤੁਹਾਡੀ ਕੰਪਨੀ, ਦੋਸਤਾਂ, ਕਾਲਜ, ਸੋਸ਼ਲ ਮੀਡੀਆ 'ਤੇ ਚਲਾਓ ਜਿਸਦਾ ਸਮਾਨ ਡੇਟਾ ਹੈ.
  ਉਨ੍ਹਾਂ ਤੱਕ ਪਹੁੰਚੋ, ਸਾਡੇ ਬਾਰੇ ਗੱਲ ਕਰੋ. ਨੂੰ ਹੋਰ ਪਹਿਲਕਦਮੀਆਂ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਦਿਲੋਂ ਬੇਨਤੀ
  ਇਕਸੁਰ ਹੋਣਾ ਇਕੋ ਜਿਹੀ ਸਮੱਸਿਆ ਜਿਸ ਵਿਚ ਅਸੀਂ ਹੱਲ ਕਰ ਰਹੇ ਹਾਂ ਵਿਚ ਮਹੱਤਵਪੂਰਨ ਹੈ. ਅਸੀਂ
  ਕ੍ਰੈਡਿਟ ਨਹੀਂ ਭਾਲਦੇ, ਅਸੀਂ ਬੱਸ ਇਸ ਜਾਣਕਾਰੀ ਤੱਕ ਪਹੁੰਚਣਾ ਚਾਹੁੰਦੇ ਹਾਂ. ਸਾਡਾ ਟੀਚਾ ਹੈ
  ਸਹੀ ਲੋਕਾਂ ਨੂੰ ਸਹੀ ਸਮੇਂ ਤੇ ਸਹੀ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ
  .ੰਗ ਨਾਲ.`,
    q11: `ਬੇਦਾਅਵਾ`,
    a112: `ਕੋਵਿਡ ਜਾਣਕਾਰੀ ਪਲੇਟਫਾਰਮ `,
    a113: ` ਕੋਵੀਡ ਐਫਵਾਈਆਈ, ਸਵੱਸਥ ਡਿਜੀਟਲ ਹੈਲਥ ਫਾ Foundationਂਡੇਸ਼ਨ ("ਸਵਸਥ") ਦੁਆਰਾ ਬਣਾਇਆ ਇੱਕ ਪਲੇਟਫਾਰਮ ਹੈ
  ਅਤੇ ਕੋਰਨਾਸਾਫੇ ਨੈਟਵਰਕ ਦੁਆਰਾ ਪ੍ਰਮਾਣਿਤ ਭੀੜ ਨੂੰ ਪ੍ਰਾਪਤ ਕੀਤੀ ਐਮਰਜੈਂਸੀ ਸੇਵਾਵਾਂ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ
  ਕੋਵੀਡ -19 ਐਮਰਜੈਂਸੀ ਲਈ ਪੂਰੇ ਭਾਰਤ ਵਿਚ.`,
    a114: `COVID ਇਨਫਾਰਮੇਸ਼ਨ ਪਲੇਟਫਾਰਮ ਵਿੱਚ ਦਿੱਤਾ ਸਾਰਾ ਡਾਟਾ ਅਤੇ ਜਾਣਕਾਰੀ ਪ੍ਰਮਾਣਿਤ ਹੈ
  ਵਲੰਟੀਅਰਾਂ ਦੇ ਸਮੂਹ ਦੁਆਰਾ ਉਨ੍ਹਾਂ ਦੀਆਂ ਉੱਤਮ ਯੋਗਤਾਵਾਂ ਅਤੇ ਸੰਸਥਾਵਾਂ ਦੁਆਰਾ
  COVID 'ਤੇ ਦਿੱਤੇ ਗਏ ਕਿਸੇ ਵੀ ਡੇਟਾ / ਜਾਣਕਾਰੀ ਲਈ ਸਾਰੀ ਜ਼ਿੰਮੇਵਾਰੀ ਤੋਂ ਇਨਕਾਰ ਕਰੋ
  ਜਾਣਕਾਰੀ ਪਲੇਟਫਾਰਮ. ਸੰਸਥਾਵਾਂ ਨੂੰ ਕਿਸੇ ਵੀ ਸਥਿਤੀ ਵਿੱਚ ਜ਼ਿੰਮੇਵਾਰ ਨਹੀਂ ਠਹਿਰਾਇਆ ਜਾ ਸਕਦਾ
  ਕਿਸੇ ਸਿੱਧੇ, ਅਸਿੱਧੇ, ਸੰਯੋਜਕ, ਨਤੀਜੇ ਵਜੋਂ, ਵਿਸ਼ੇਸ਼, ਜ਼ੁਰਮਾਨੇ ਲਈ,
  ਮਿਸਾਲੀ, ਜਾਂ ਕੋਈ ਹੋਰ ਨੁਕਸਾਨ ਜਿਸ ਨੂੰ COVID ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਨਾਲ ਪੈਦਾ ਹੁੰਦਾ ਹੈ
  ਪਲੇਟਫਾਰਮ. ਸੰਗਠਨ ਦੁਆਰਾ ਕੋਈ ਨਿਰਧਾਰਤ ਨਤੀਜਿਆਂ ਦਾ ਕੋਈ ਭਰੋਸਾ ਨਹੀਂ ਹੁੰਦਾ
  ਕੋਵਿਡ ਜਾਣਕਾਰੀ ਪਲੇਟਫਾਰਮ ਦੀ ਵਰਤੋਂ ਦੀ. ਉਪਭੋਗਤਾ ਇਸ ਨੂੰ ਵਰਤ ਕੇ ਸਮਝਦੇ ਹਨ
  COVID ਜਾਣਕਾਰੀ ਪਲੇਟਫਾਰਮ 'ਤੇ ਮੁਹੱਈਆ ਕੋਈ ਵੀ ਡਾਟਾ ਜਾਂ ਜਾਣਕਾਰੀ' ਤੇ ਹੋਵੇਗੀ
  ਉਹਨਾਂ ਦੇ ਆਪਣੇ ਜੋਖਮ ਅਤੇ ਉਲੰਘਣਾ ਅਤੇ ਕਿ ਸੰਸਥਾਵਾਂ ਇਸ ਲਈ ਜ਼ਿੰਮੇਵਾਰ ਨਹੀਂ ਹਨ
  ਅਜਿਹੇ ਡੇਟਾ / ਜਾਣਕਾਰੀ ਦੀ ਪੇਸ਼ਕਸ਼ ਲਈ ਕੋਈ ਨਤੀਜੇ. COVID ਜਾਣਕਾਰੀ
  ਪਲੇਟਫਾਰਮ ਜਾਣਕਾਰੀ ਦੇ ਉਦੇਸ਼ਾਂ ਲਈ, ਦੁਆਰਾ ਸੰਚਾਲਿਤ ਵੈਬਸਾਈਟਾਂ ਦੇ ਲਿੰਕ ਪ੍ਰਦਾਨ ਕਰ ਸਕਦਾ ਹੈ
  ਹੋਰ ਸੰਸਥਾਵਾਂ ਅਤੇ ਵਿਅਕਤੀਆਂ, ਪਰ ਕਿਸੇ ਵੀ ਜ਼ਿੰਮੇਵਾਰੀ ਨੂੰ ਸਮਰਥਨ ਜਾਂ ਸਵੀਕਾਰ ਨਹੀਂ ਕਰਦਾ
  ਅਜਿਹੀ ਸਮਗਰੀ ਲਈ, ਜਾਂ ਅਜਿਹੀਆਂ ਵੈਬਸਾਈਟਾਂ ਦੀ ਵਰਤੋਂ ਲਈ. ਸਮੇਤ ਕਿਸੇ ਵੀ ਕਿਸਮ ਦੀ ਕੋਈ ਗਰੰਟੀ ਨਹੀਂ
  ਪਰ ਤੀਜੀ ਧਿਰ ਦੇ ਅਧਿਕਾਰਾਂ ਦੀ ਉਲੰਘਣਾ ਦੀ ਗਰੰਟੀ ਤੱਕ ਸੀਮਿਤ ਨਹੀਂ,
  ਸਿਰਲੇਖ, ਵਪਾਰਕਤਾ, ਕਿਸੇ ਖਾਸ ਉਦੇਸ਼ ਲਈ ਤੰਦਰੁਸਤੀ ਸਤਿਕਾਰ ਨਾਲ ਦਿੱਤੀ ਜਾਂਦੀ ਹੈ
  ਇਸ ਵੈਬਸਾਈਟ ਦੀ ਸਮਗਰੀ ਜਾਂ ਦੂਜੇ ਇੰਟਰਨੈਟ ਸਰੋਤਾਂ ਲਈ ਇਸਦੇ ਹਾਈਪਰਲਿੰਕਸ.`
};
